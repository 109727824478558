<template>
	<div class="container3">
		<div class="cateloupan_status">
			<span>状态:</span>
			<el-checkbox-group v-model="loupan_status">
				<el-checkbox :label="1">待售</el-checkbox>
				<el-checkbox :label="2">在售</el-checkbox>
				<el-checkbox :label="3">售罄</el-checkbox>
			</el-checkbox-group>
		</div>
  <div class="plugin">
          <div :class="{'pbtn':true, 'activebtn':'vector'=== currentMapLayer.type}" @click="toggleMapType('vector')">矢量地图</div>
          <div :class="{'pbtn':true, 'activebtn':'satellite'=== currentMapLayer.type}" @click="toggleMapType('satellite')">影像地图</div>
      </div>
		<div class="mapcontainer3">
        

        <l-map
        ref="map"
        style="width: 100%; height: 100%; z-index: 10"
        :zoom="12"
        :center="center"
      >
         <l-tile-layer :url="currentMapLayer.url" ></l-tile-layer>
         <l-tile-layer :url="currentMapLayer.texturl" :attribution="currentMapLayer.attribution"></l-tile-layer>

        <!-- 地块点 -->
        <l-marker
          v-if="detail.lat_gaode && detail.lng_gaode"
          :name="detail.name"
          :icon="icon(detail.usecate)"
          :lat-lng="[detail.lat_gaode, detail.lng_gaode]"
          :offset="[0, 20]"
        >
        </l-marker>
        <!-- 地库多边形 -->
        <l-polygon
          v-for="item in polygonpath"
          :key="item.id"
          :lat-lngs="item.path"
          :color="item.strokeColor"
          :fill-color="item.filecolor"
          :fill-opacity="0.6"
        >
        </l-polygon>

        <l-marker
          v-for="item in listdata"
          :key="item.id"
          :icon="icon()"
          :lat-lng="[item.lat_gaode, item.lng_gaode]"
          :offset="[8, 0]"
          :visible="item.visible"
        >
          <l-tooltip
            :options="{
              permanent: true,
              interactive: true,
              offset: [4, -16],
              className: 'color',
            }"
          >
            <div class="tooltipInnerBox">
              <!-- <img cLass="icon" :src="item.imgurl" /> -->
              <div class="title">
                {{ item.loupanzhuizong }}
              </div>
            </div>
          </l-tooltip>
        </l-marker>
      </l-map>


		</div>
		<div class="swipercontainer3">
			<div class="zhibiaotitle">
				地块规划指标
			</div>
			<el-table class="tabledata" :data="listdata" style="margin-bottom: 20px;" row-key="id" border>
				<el-table-column prop="loupanzhuizong" label="项目名称">
				</el-table-column>
				<el-table-column prop="locationnum" label="宗地编号">
				</el-table-column>
				<!-- <el-table-column prop="locationname" label="地块名称">
				</el-table-column> -->
				<el-table-column prop="company" label="开发商">
				</el-table-column>
				<el-table-column prop="tradedate" label="拿地时间">
				</el-table-column>
				<el-table-column prop="floorprice" label="楼面价">
				</el-table-column>
				<el-table-column prop="first_open_time" label="首次开盘时间">
				</el-table-column>
				<el-table-column prop="give_time" label="交房时间">
				</el-table-column>
				<el-table-column prop="acreage" label="占地面积(㎡)">
				</el-table-column>
				<el-table-column prop="usecatename" label="物业类型">
				</el-table-column>
<!-- 				
				<el-table-column prop="building_category" label="建筑类型">
				</el-table-column> -->
				<el-table-column prop="buildacreage" label="建筑面积(㎡)">
				</el-table-column>
				<el-table-column prop="volume" label="容积率">
				</el-table-column>
			
				<el-table-column prop="business_type" label="业态类型">
				</el-table-column>
				<el-table-column prop="main_house_type" label="主力户型">
				</el-table-column>
				<el-table-column prop="decoration" label="装修情况">
				</el-table-column>
				<el-table-column prop="parking_space" label="停车位">
				</el-table-column>
				<el-table-column prop="parking_space_ratio" label="车位配比">
				</el-table-column>
				<el-table-column prop="selling_price" label="在售价格(元/㎡)">
				</el-table-column>
			</el-table>

		</div>
	</div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LIcon,
} from "vue2-leaflet";
import { vectorMapLayer,satelliteMapLayer,leafletmapurl,leafletmaptexturl,leafletmapyunxuanurl, isgaode } from "@/settings.js";


import { icon } from "leaflet";
import { getDetail, downloadFileByCate, loupanData } from "../../api/data";

export default {
	name: 'FrontendPcBigdataLandlayout',
	 components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LPolyline,
        LPolygon,
        LIcon,
        LTooltip,
    },


	data() {
		return {
			id: 0,
			diyLabel: {
				position: []
			},
             //矢量地图对象
       vectorMapLayer:vectorMapLayer ,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer:{url:'',texturl:'',attribution:'',type:'vector'},

		
			loupan_status: [1],
			listdata: [],
            polygonpath: [],

            centerpoint: [32.1212, 120.1212],
            crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
            url: leafletmapurl,
            attribution: "",
            staticAnchor: [16, 37],
            center: [0, 0],
            zoom: 18,
            detail: {},
            pointsList: [],

            checkAll: false,
            checkedCities: [1, 2],

            isIndeterminate: true,

            id: 0,
            map: {},
            detail: {},
            myDis: {},
            imglist: [],


		};
	},

  computed: {
    city_plate_id: {
      get() {
        let a = this.$store.state.currentLandData.cid;
        return a;
      },
    },

    shi: {
      get() {
        let a = this.$store.state.currentLandData.cid;
        return a;
      },
    },

    currentLandData: {
      get() {
        return this.$store.state.currentLandData;
      },
    },
  },

	watch: {
		loupan_status: {
			handler(nv, ov) {
				if (nv.length === 0) {
					this.loupan_status = [1]
				} else {
					this.getDataBylnglat()
				}
			}
		},
		deep: true
	},
	mounted() {

		if (this.$route.params.id) {
			this.id = this.$route.params.id
		}
		this.getMapDetail().then(res => {
			
			this.getDataBylnglat()

		})


	},
  created() {
     this.currentMapLayer = this.vectorMapLayer;
     this.currentMapLayer.type = 'vector'
  },


  methods: {
     toggleMapType(type) {
      if (type === 'vector') {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = 'vector'
      } else if (type === 'satellite') {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = 'satellite'
      }
    },
        
    icon(num) {
      if (num == null || num == "") {
        return icon({
          iconUrl: require("@/assets/opcity.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else {
        return icon({
          iconUrl: require("@/assets/type" + num + ".png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
    },


		//根据经纬度获取数据
		getDataBylnglat() {

      const map = this.$refs.map.mapObject; // 获取地图实例
      const bounds = map.getBounds(); // 获取当前视图的边界

      // 如果需要经纬度值，可以使用bounds对象的_northEast和_southWest属性
      const northEast = bounds._northEast;
      const southWest = bounds._southWest;
      //获取边界条件
      const params = {
        ll_lng: southWest.lng, // 经度 左下角
        ll_lat: southWest.lat, // 纬度 左下角
        ru_lng: northEast.lng, // 经度 右上角
        ru_lat: northEast.lat, // 纬度 右上角
        loupan_status: this.loupan_status.toString(),
				id: this.id,
        is_gaode:isgaode
      };


			

			loupanData(params).then(res => {
				this.listdata = res.data
                
			})
		},

		getImgList() {
			downloadFileByCate({ pid: this.id, category_id: 9 }).then(res => {
				console.log('imglist', res.data)
				this.imglist = res.data
			})

		},

		
    getMapDetail() {
      var that = this;
      return new Promise(function (resolve, reject) {
        getDetail({ id: that.currentLandData.id,
        is_gaode:isgaode }).then((res) => {
          that.detail = res.data;

          that.handerPolygondata(res.data.id, res.data.set_gaode);

          that.center = [res.data.lat_gaode, res.data.lng_gaode];
          resolve(res.data);
        });
      });
    },

    handerPolygondata(id, allset) {
      let polygonpath = [];
      const setObj = JSON.parse(allset);
      console.log("setObj", setObj);
      const rowpolygonpath = setObj.map((itemSingle) => {
        // data 是一个多变形的数据
        // 处理一个多变形的方法
        const singlepath = this.handleSingleData(id, itemSingle);
        polygonpath.push(singlepath);
      });
      this.polygonpath = polygonpath;
    },

    handleSingleData(id, data) {
      let obj = {
        id: id,
        path: [],
        filecolor: "#000",
        strokcolor: "#409EFF",
        weight: this.strokeWeight,
        strokeopacity: this.strokeOpacity,
        fillopacity: this.fillOpacity,
      };
      const pointobj = data.lnglat.split(";");
      const pointArr = pointobj.map((point) => {
        const lnglat = point.split(",");
        return [parseFloat(lnglat[1]), parseFloat(lnglat[0])];
      });
      obj.path = pointArr;
      obj.filecolor = data.color;

      return obj;
    },



	},
};
</script>

<style lang="scss" scoped>
.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}

/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}

.container3 {
	width: 100%;
	height: calc(100vh - 188px);
	color: #333;
	// background:Red;
	overflow-y: scroll;
	position: relative;

}

.cateloupan_status {
	span {
		font-weight: bold;
		font-size: 14px;
		margin-right: 10px;
		display: inline-block;
	}

	display: flex;
	position: absolute;
	top: 30px;
	right: 80px;
	z-index:200;
	background:#fff;
	border-radius: 5px;
	height:40px;
	padding:0 20px;
	justify-content: center;
	align-items: center;
}

.mapcontainer3 {
	display: flex;
	width: 98%;
	margin: 0 1%;
	height: calc(100vh - 308px);
	margin-top: 10px;

	.bmap-demo {
		flex: 1;
	}
}

.zhibiaotitle {
	width: 98%;
	margin-top: 5px;
	margin-left: 1%;
	height: 40px;
	background: #28A7E1;
	border-radius: 8px 8px 0px 0px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 40px;
}

.swipercontainer3 {
	height: 400px;
}

.tabledata {
	width: 98%;
	margin-left: 1%;
}





.labelbox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	border-radius: 5px;

	.labelcontent {
		border: solid 1px #fff;
		background:  #fff;
		border-radius: 4px;
		color: #333;
		font-size: 14px;
		padding: 4px;
		display: flex;
		flex-direction: row;
		padding: 5px 10px;
		justify-content: space-between;
		align-items: center;
		box-shadow:0 0 10px #06C;

		.iconimg {
			height: 30px;
			width: 30px;
		}

		.labeltext {
			margin-left: 10px;
		}

		// mar
	}

	.labeldown {
		margin-left:15px;
		width: 0;
		height: 0;
		border-top: 10px solid  #fff;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;

	}
}


.tooltipInnerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon {
    height: 20px;
    width: 2px;
  }
  .title {
    font-size: 16px;
    margin-left: 5px;
  }
}

</style>