<template>
	<div>
		<!-- <jing></jing> -->
		<leaflet-jing></leaflet-jing>
	</div>
</template>

<script>
// import Jing from '@/components/bmap/Jing'
import leafletJing from '@/components/leaflet/leafletJing'
export default {
	name: 'jingpin',
	components: {
		// Jing,
		leafletJing
	},
  mounted () {
	},
  data () {
    return {
    }
  },
}
</script>

<style>

</style>